import { FC } from 'react'
import Emoji from 'components/Emoji'

interface IProps {
  name: string
}

const LastFilterSettingsSingleItem: FC<IProps> = ({ name }) => {
  return (
    <div className="last-filter-settings-item paragraph--small paragraph--bold">
      <Emoji>{name}</Emoji>
    </div>
  )
}

export default LastFilterSettingsSingleItem
