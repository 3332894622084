import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import FilterUtils from 'utils/filter'

export const useFilter = <T extends object>(
  initialQuery: { [x: string]: string | string[] },
  keys: string[] = [],
  options?: {
    onUpdate?: (query: T) => void
  }
) => {
  const [state, setState] = useState<{
    query: T
  }>({
    query: FilterUtils.parseRouterQueryToArrayValues<T>(initialQuery, keys),
  })
  const router = useRouter()

  useEffect(() => {
    const nextQuery = FilterUtils.parseRouterQueryToArrayValues<T>(initialQuery, keys)

    setState((prev) => ({
      ...prev,
      query: nextQuery,
    }))
    options?.onUpdate?.(nextQuery)
  }, [initialQuery])

  const getNextQuery = () => {
    const nextQuery = {}

    keys.forEach((key) => {
      nextQuery[key] = state.query[key] ?? []
    })

    return nextQuery as T
  }

  const addToQuery = (key: string, value: string) => {
    const nextQuery = getNextQuery()

    if (Array.isArray(nextQuery[key])) {
      if (nextQuery[key].includes(value)) {
        return
      }

      nextQuery[key].push(value)
    } else if (typeof nextQuery[key] === 'string') {
      nextQuery[key] = value
    }

    updateRouter(nextQuery)
  }

  const forceQuery = (query: T) => {
    const nextQuery = query

    updateRouter(nextQuery)
  }

  const replaceQuery = (key: string, value: string | string[]) => {
    const nextQuery = getNextQuery()

    nextQuery[key] = value

    updateRouter(nextQuery)
  }

  const removeFromQuery = (key: string, value: string) => {
    const nextQuery = getNextQuery()

    if (Array.isArray(nextQuery[key])) {
      const index = nextQuery[key].indexOf(value)

      if (index > -1) {
        nextQuery[key].splice(index, 1)
      }
    } else if (typeof nextQuery[key] === 'string') {
      nextQuery[key] = ''
    }

    updateRouter(nextQuery)
  }

  const resetFilter = (e) => {
    e.preventDefault()
    const nextQuery = state.query

    Object.keys(nextQuery).forEach((key) => {
      nextQuery[key] = Array.isArray(nextQuery[key]) ? [] : ''
    })

    updateRouter(nextQuery)
  }

  const updateRouter = (query: T) => {
    const nextQueryString = FilterUtils.buildJobsQuery(query)
    router.push(nextQueryString, nextQueryString, { shallow: true })
    setState((prev) => ({
      ...prev,
      query,
    }))
  }

  const getActiveQueries = () => {
    const activeValues = {}

    Object.entries(state.query).forEach(([key, value]) => {
      if (value.length) {
        activeValues[key] = value
      }
    })

    return activeValues
  }

  const isActive = Object.keys(getActiveQueries()).length !== 0

  return {
    ...state,
    addToQuery,
    removeFromQuery,
    replaceQuery,
    forceQuery,
    resetFilter,
    activeQueries: getActiveQueries(),
    isActive,
  }
}
