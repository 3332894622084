import React, { FC } from 'react'
import { GrammarCaseKey } from 'translations/types'
import useTranslation from 'translations/hooks/useTranslation'
import { CategorySlug } from 'types/category'
import StringUtils from 'utils/string'
import { FilterContext } from 'types/filter'

type IProps = {
  filterData: any
  resultsCount: number
  query: { [key: string]: string[] }
  context?: FilterContext
  onReset: (e?: any) => void
  onPillClick?: (key: string, value: string) => void
}

const getCurrencyMark = (currencyValue: string) => {
  switch (currencyValue) {
    case 'CZK':
      return 'CZK'
    case 'EUR':
    default:
      return '€'
  }
}

const useFormatLabel = () => {
  const { t } = useTranslation()

  return (data: { key: string; active: any; supported: any }) => {
    const items = data.supported?.filter((item) => data.active.includes(item.slug)) || []

    if (!items.length) return ''

    const translateFreelanceCategory = (slug: string) =>
      t(`freelancers.categoryName[${slug}]`, {
        grammarCase: GrammarCaseKey.Other,
      })

    const translateSkillLevel = (slug: string) =>
      t(`freelancers.skillLevel.plain.${slug}`)

    const translateAvailabilityStatus = (slug: string) =>
      t(`freelancers.availabilityStatus.${slug}`)

    const getTranslatedItems = () => {
      return items.map((item) => {
        if (
          Object.values(CategorySlug).includes(item.slug as CategorySlug) &&
          translateFreelanceCategory(item.slug)?.length
        ) {
          return {
            ...item,
            name: StringUtils.capitalize(translateFreelanceCategory(item.slug)),
          }
        }

        if (item.slug === 'manifest') {
          return {
            ...item,
            name: t('manifest.tag'),
          }
        }

        if (data.key === 'skillLevels') {
          return {
            ...item,
            name: translateSkillLevel(item.slug),
          }
        }

        if (data.key === 'availabilities') {
          return {
            ...item,
            name: translateAvailabilityStatus(item.slug),
          }
        }

        return item
      })
    }

    return getTranslatedItems().map((item) => ({
      key: data.key,
      value: item.slug,
      label: item.name,
    }))
  }
}

const ActiveFilterSummary: FC<IProps> = ({
  filterData,
  query,
  resultsCount,
  onReset,
  onPillClick,
  context,
}) => {
  const formatLabel = useFormatLabel()
  const { t } = useTranslation()

  const getLabels = () => {
    const labels = []

    Object.entries(query).forEach(([key, value]) => {
      if (key === 'searchTerm') {
        labels.push({ key, value: value.join(), label: value.join() })
      } else if (key === 'maxAge') {
        labels.push({ key, value: value.join(), label: t('label.new') })
      } else if (key === 'pro') {
        labels.push({ key, value: key, label: t('label.pro') })
      } else if (key === 'available') {
        labels.push(t('label.available'))
      } else if (key === 'salary') {
        labels.push({
          key,
          value: value[0],
          label: `${value[0]} ${getCurrencyMark(value[1])}`,
        })
      } else {
        labels.push(...formatLabel({ key, active: value, supported: filterData[key] }))
      }
    })

    return labels
  }

  const contextLabel = () => {
    switch (context) {
      case 'jobs':
        return t('common.jobsCount', { count: resultsCount })
      case 'freelancers':
        return t('common.freelancersCount', { count: resultsCount })
      case 'companies':
        return t('common.companiesCount', { count: resultsCount })
      default:
        return ''
    }
  }

  return (
    <div>
      <span className="heading-3 margin-right-10 active-filter-summary-label">
        🔎 {contextLabel()}
      </span>{' '}
      {getLabels().map((label, index) => (
        <span
          key={`${label.value}-${index}`}
          className="pill pill--filter-active pill--closable"
          onClick={() => onPillClick(label.key, label.value)}
        >
          {label.label}
        </span>
      ))}
      <span className="link margin-left-10" onClick={onReset}>
        {t('filter.resetFull')}
      </span>
    </div>
  )
}

export default ActiveFilterSummary
