import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'
import FilterPill from 'components/filter/FIlterPill'

type IProps = {
  isActive: boolean
  isOpen: boolean
  onClick: () => void
  children: ReactNode
}

const FilterTab: FC<IProps> = ({ isActive, isOpen, children, onClick }) => {
  return (
    <FilterPill
      className={classNames('relative pill--no-line-through pill--dropdown', {
        'pill--filter-inactive': !isOpen && !isActive,
        'pill--dropdown-open': isOpen,
        'pill--dropdown-active': isActive,
      })}
      onClick={onClick}
      isActive={isOpen || isActive}
    >
      {children}
    </FilterPill>
  )
}

export default FilterTab
