import SearchIcon from '@material-ui/icons/Search'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import debounce from 'debounce'
import classNames from 'classnames'
import { useSearch } from 'hooks/useSearch'
import useTranslation from 'translations/hooks/useTranslation'
import useDeviceDetect from 'hooks/useDeviceDetect'
import SearchResultItem from 'components/search/SearchResultItem'
import {
  getFlattenedIds,
  getSearchItemById,
  getSearchResult,
  hasSearchResults,
} from 'components/search/search-utils'
import SearchResultCategory from 'components/search/SearchResultCategory'
import { FilterQuery, IFilterData } from 'types/filter'
import TrackingUtils from 'utils/tracking'
import RouterUtils from 'utils/router'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { SearchConfig } from 'types/search'

const SEARCH_DEBOUNCE = 200
const DEFAULT_SELECTED_SEARCH_ITEMS_INDEX = 0

type IProps = {
  filterData: IFilterData
  addToQuery: (key: keyof FilterQuery, value: string, trackEvent?: boolean) => void
  placeholder?: string
  context?: SearchConfig['context']
  onResetFilterClick?: (e: any) => void
}

const Search: FC<IProps> = ({
  context,
  filterData,
  addToQuery,
  placeholder,
  onResetFilterClick,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(
    DEFAULT_SELECTED_SEARCH_ITEMS_INDEX
  )
  const searchInputRef = useRef(null)
  const [searchResultData, setSearchResultData] = useState(null)
  const searchContainerRef = useRef()
  const [searchTerm, setSearchTerm] = useState('')
  const [flattenedIds, setFlattenedIds] = useState([])
  const { data, isLoading, isError, search } = useSearch(searchTerm, { context })

  const { t } = useTranslation()
  const { isDesktop } = useDeviceDetect()

  useEffect(() => {
    if (isDesktop && searchContainerRef?.current) {
      const searchContainerOffsetTop =
        (searchContainerRef.current as { offsetTop?: number })?.offsetTop ?? 0
      const horizontalScrollPosition = window.scrollY

      if (searchContainerOffsetTop > horizontalScrollPosition) {
        searchInputRef.current.focus()
      }
    }
  }, [])

  const getAvailableSearchData = useCallback(() => {
    return {
      companies: data.companies || [],
      categories: filterData.jobCategories || [],
      jobTypes: filterData.jobTypes || [],
      skills: filterData.skillLevels || [],
      countries: filterData.countries || [],
      cities: filterData.cities || [],
      tags: filterData.tags || [],
      profiles: data.profiles || [],
    }
  }, [data, filterData])

  const setSearchResults = (value: string) => {
    const results = getSearchResult(getAvailableSearchData(), value)
    setFlattenedIds(getFlattenedIds(results))
    setSearchResultData(results)
  }

  const debouncedSearch = debounce(search, SEARCH_DEBOUNCE, true)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchTerm(value)
    setSearchResults(value)
    debouncedSearch(value)
  }

  const handleItemClick = (key: keyof FilterQuery, value: string) => {
    closeSearch()
    addToQuery(key, value, false)
    TrackingUtils.event('filter', {
      event_action: 'search_item_select',
      event_data1: key,
      event_data2: value,
    })
  }

  const closeSearch = () => {
    setSearchTerm('')
    setSelectedIndex(DEFAULT_SELECTED_SEARCH_ITEMS_INDEX)
    setFlattenedIds([])
  }

  useOnClickOutside(searchContainerRef, closeSearch)

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape' || e.key === 'Esc') {
      e.preventDefault()

      closeSearch()
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()

      setSelectedIndex((prevSelectedIndex) => {
        return prevSelectedIndex === 0 ? flattenedIds.length - 1 : prevSelectedIndex - 1
      })
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()

      setSelectedIndex((prevSelectedIndex) =>
        prevSelectedIndex < flattenedIds.length - 1
          ? prevSelectedIndex + 1
          : prevSelectedIndex
      )
    } else if (e.key === 'Enter') {
      e.preventDefault()

      const item = getSearchItemById(
        getAvailableSearchData(),
        flattenedIds[selectedIndex]
      )

      if (['company', 'profile'].includes(item?.type)) {
        TrackingUtils.event('search', {
          event_action: 'handleItemClick',
          event_data1: item.type,
          event_data2: item.data.slug,
        })

        if (item?.type === 'company') {
          RouterUtils.push(`${item.type}/${item.data.slug}`)
        }

        if (item?.type === 'profile') {
          RouterUtils.push(`freelancers/detail/${item.data.shortId}`)
        }
      } else if (item?.type) {
        handleItemClick(item.type as keyof FilterQuery, item.data.slug)
      } else {
        handleItemClick('searchTerm', searchTerm)
      }
    }
  }
  const selectedId = flattenedIds[selectedIndex] || null

  return (
    <div className="search__input-container">
      <div className="search__input-icon">
        <SearchIcon />
      </div>

      {isError && (
        <span className="search__error">
          ⚠️ <span>{t('common.error.pleaseRefresh')}</span>
        </span>
      )}

      <input
        className={classNames('search__input', {
          'search__input-reset': !!onResetFilterClick,
        })}
        name="search__input"
        autoComplete="off"
        type="text"
        placeholder={placeholder}
        ref={searchInputRef}
        value={searchTerm}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
      />

      {onResetFilterClick && (
        <span
          tabIndex={0}
          className="search__input-reset-button"
          onClick={onResetFilterClick}
        >
          <span className="filter__reset-icon">+</span>
        </span>
      )}

      {searchTerm && (
        <div className="search__results">
          {!hasSearchResults(searchResultData) && (
            <div className="search__result-wrapper">
              <p className="search__result-heading">{t('filter.search')}</p>
              <SearchResultItem
                item={{
                  name: searchTerm,
                  slug: searchTerm,
                  _id: null,
                }}
                selectedId={selectedId}
                onItemClick={() => handleItemClick('searchTerm', searchTerm)}
              />
            </div>
          )}
          {hasSearchResults(searchResultData) && (
            <>
              <SearchResultCategory
                selectedId={selectedId}
                queryName="jobCategories"
                heading={t('common.category')}
                data={searchResultData.categories}
                onItemClick={handleItemClick}
              />
              <SearchResultCategory
                selectedId={selectedId}
                queryName="tags"
                heading={t('filter.tags')}
                data={searchResultData.tags}
                onItemClick={handleItemClick}
              />
              <SearchResultCategory
                selectedId={selectedId}
                queryName="skillLevels"
                heading={t('filter.skillLevel')}
                data={searchResultData.skills}
                onItemClick={handleItemClick}
              />
              <SearchResultCategory
                selectedId={selectedId}
                queryName="countries"
                heading={t('common.country')}
                data={searchResultData.countries}
                onItemClick={handleItemClick}
              />
              <SearchResultCategory
                selectedId={selectedId}
                queryName="cities"
                heading={t('common.city')}
                data={searchResultData.cities}
                onItemClick={handleItemClick}
              />
              <SearchResultCategory
                selectedId={selectedId}
                queryName="jobTypes"
                heading={t('filter.cooperationForm')}
                data={searchResultData.jobTypes}
                onItemClick={handleItemClick}
              />
              {['jobs', 'companies'].includes(context) && (
                <SearchResultCategory
                  loading={isLoading}
                  selectedId={selectedId}
                  urlConfig={{ prefix: 'company', identifier: 'slug' }}
                  heading={t('common.companies')}
                  data={searchResultData.companies}
                />
              )}
              {context === 'freelancers' && (
                <SearchResultCategory
                  loading={isLoading}
                  selectedId={selectedId}
                  urlConfig={{ prefix: 'freelancers/detail', identifier: 'shortId' }}
                  heading={t('common.freelancers')}
                  data={searchResultData.profiles}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default Search
