import React from 'react'
import useTranslation from 'translations/hooks/useTranslation'

interface IProps {
  translationKey: string
}

const EmptyState: React.FC<IProps> = ({ translationKey }) => {
  const { t } = useTranslation()

  return (
    <div className="container job-container empty-state-container">
      <div className="empty-state">
        <p className="paragraph center no-margin">{t(translationKey)}</p>
      </div>
    </div>
  )
}

export default EmptyState
