import React from 'react'
import classnames from 'classnames'
import TrackingUtils from 'utils/tracking'
import useTranslation from 'translations/hooks/useTranslation'
import LastFilterSettingsItem from './LastFilterSettingsItems'
import NewJobsLabel from './NewJobsLabel'
import LastFilterSettingsSingleItem from './LastFilterSettingsSingleItem'

import type {
  City,
  Country,
  FilterQuery,
  JobCategory,
  JobType,
  SkillLevel,
  Tag,
} from 'types/filter'

interface IProps {
  withContainer?: boolean
  cities?: City[]
  countries?: Country[]
  jobTypes?: JobType[]
  jobCategories?: JobCategory[]
  newJobsLabel: boolean
  settings: Partial<FilterQuery> | null
  skillLevels?: SkillLevel[]
  tags?: Tag[]
  onLastFilterClick: () => void
}

export default function LastFilterSettings({
  cities,
  countries,
  jobTypes,
  jobCategories,
  newJobsLabel,
  settings,
  skillLevels,
  tags,
  withContainer,
  onLastFilterClick,
}: IProps) {
  const { t } = useTranslation()

  if (settings === null) {
    return null
  }

  const validLastFilterSettings =
    settings?.cities?.length ||
    settings?.countries?.length ||
    settings?.jobTypes?.length ||
    settings?.jobCategories?.length ||
    settings?.skillLevels?.length ||
    settings?.tags?.length ||
    settings?.available ||
    settings?.pro ||
    settings?.paymentTypes ||
    settings?.searchTerm

  if (!validLastFilterSettings) {
    return null
  }

  const handleActivateFilterRequest = () => {
    TrackingUtils.event('filter', { event_action: 'last-search' })
    onLastFilterClick()
  }

  return (
    <div
      className={classnames('last-filter-settings', {
        container: withContainer,
      })}
    >
      <div className="last-filter-settings__items">
        <span className="last-filter-settings__title paragraph--light paragraph--small">
          <span className="last-filter-settings__title--desktop">
            {t('common.search.lastSearched')}:
          </span>
          <span className="last-filter-settings__title--mobile">
            {t('common.search.lastSearchedMobile')}:
          </span>
        </span>
        <div
          className="last-filter-settings__items-handler"
          onClick={handleActivateFilterRequest}
        >
          {!!settings?.searchTerm?.length && (
            <LastFilterSettingsSingleItem name={settings.searchTerm} />
          )}
          {settings.jobCategories && (
            <LastFilterSettingsItem
              settings={settings.jobCategories}
              supportedSettingsValues={jobCategories}
            />
          )}
          {settings.jobTypes && (
            <LastFilterSettingsItem
              settings={settings.jobTypes}
              supportedSettingsValues={jobTypes}
            />
          )}
          {settings.paymentTypes?.map((type) => (
            <LastFilterSettingsSingleItem
              key={type}
              name={t(`filter.paymentType.type[${type}]`)}
            />
          ))}
          {settings.countries && (
            <LastFilterSettingsItem
              settings={settings.countries}
              supportedSettingsValues={countries}
            />
          )}
          {settings.cities && (
            <LastFilterSettingsItem
              settings={settings.cities}
              supportedSettingsValues={cities}
            />
          )}
          {settings.skillLevels && (
            <LastFilterSettingsItem
              settings={settings.skillLevels}
              supportedSettingsValues={skillLevels}
            />
          )}
          {settings.tags && (
            <LastFilterSettingsItem
              settings={settings.tags}
              supportedSettingsValues={tags}
            />
          )}
          {!!settings.available?.length && (
            <LastFilterSettingsSingleItem name={t('label.available')} />
          )}
          {!!settings.pro?.length && <LastFilterSettingsSingleItem name="🔥 PRO" />}
          {newJobsLabel && (
            <span className="last-filter-settings__new-label">
              <NewJobsLabel settings={settings} />
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
