import React from 'react'
import Emoji from 'components/Emoji'
import SearchResultItem from './SearchResultItem'
import type { FilterQuery } from 'types/filter'

interface IProps {
  data: any[]
  heading: string
  queryName?: keyof FilterQuery
  selectedId?: string | null
  loading?: boolean
  onItemClick?: (key: keyof FilterQuery, value: string) => void
  urlConfig?: {
    prefix: string
    identifier: string
  }
}

const SearchResultCategory: React.FC<IProps> = ({
  data,
  heading,
  queryName,
  urlConfig,
  selectedId,
  loading,
  onItemClick,
}) => {
  if (data.length === 0 && !loading) {
    return null
  }

  return (
    <div className="search__result-wrapper">
      <p className="search__result-heading">
        {heading}{' '}
        {loading && (
          <span className="search__result-heading-loading">
            <Emoji>⏳</Emoji>
          </span>
        )}
      </p>

      {data.map((item) => {
        return (
          <SearchResultItem
            key={item._id}
            item={item}
            queryName={queryName}
            urlConfig={urlConfig}
            selectedId={selectedId}
            onItemClick={onItemClick}
          />
        )
      })}
    </div>
  )
}

export default SearchResultCategory
