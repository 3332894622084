export const hasSearchResults = (data: { [any: string]: any }) => {
  return (
    data?.categories?.length > 0 ||
    data?.companies?.length > 0 ||
    data?.countries?.length > 0 ||
    data?.jobTypes?.length > 0 ||
    data?.cities?.length > 0 ||
    data?.skills?.length > 0 ||
    data?.tags?.length > 0 ||
    data?.profiles?.length > 0
  )
}

const loopDataAndPushID = (loopData, flattenedIds) => {
  if (Array.isArray(loopData)) {
    loopData.forEach((item) => flattenedIds.push(item._id))
  }

  return flattenedIds
}

export const getFlattenedIds = (data: { [any: string]: any }) => {
  let flattenedIds = []

  if (!data) {
    return flattenedIds
  }

  flattenedIds = loopDataAndPushID(data.categories, flattenedIds)
  flattenedIds = loopDataAndPushID(data.tags, flattenedIds)
  flattenedIds = loopDataAndPushID(data.skills, flattenedIds)
  flattenedIds = loopDataAndPushID(data.countries, flattenedIds)
  flattenedIds = loopDataAndPushID(data.cities, flattenedIds)
  flattenedIds = loopDataAndPushID(data.jobTypes, flattenedIds)
  flattenedIds = loopDataAndPushID(data.companies, flattenedIds)
  flattenedIds = loopDataAndPushID(data.profiles, flattenedIds)

  return flattenedIds
}

export const getSearchItemById = (data: { [any: string]: any }, id: string) => {
  const categories = data.categories.find((item) => item._id === id)
  if (categories) {
    return { type: 'jobCategories', data: categories }
  }

  const tags = data.tags.find((item) => item._id === id)
  if (tags) {
    return { type: 'tags', data: tags }
  }

  const skills = data.skills.find((item) => item._id === id)
  if (skills) {
    return { type: 'skillLevels', data: skills }
  }

  const countries = data.countries.find((item) => item._id === id)
  if (countries) {
    return { type: 'countries', data: countries }
  }

  const cities = data.cities.find((item) => item._id === id)
  if (cities) {
    return { type: 'cities', data: cities }
  }

  const jobTypes = data.jobTypes.find((item) => item._id === id)
  if (jobTypes) {
    return { type: 'jobTypes', data: jobTypes }
  }

  const companies = data.companies.find((item) => item._id === id)
  if (companies) {
    return { type: 'company', data: companies }
  }

  const profiles = data.profiles.find((item) => item._id === id)
  if (profiles) {
    return { type: 'profile', data: profiles }
  }

  return null
}

const normalizeString = (str: string) => {
  return str.replaceAll(/[^a-zA-Z0-9]/g, '').toLowerCase()
}

const hasSubstring = (
  str?: string,
  substr?: string,
  options?: { searchInsideString?: boolean }
) => {
  const normalizedStr = normalizeString(str || '')
  const normalizedSubstr = normalizeString(substr || '')

  return options?.searchInsideString
    ? normalizedStr.includes(normalizedSubstr)
    : normalizedStr?.startsWith(normalizedSubstr)
}

const filterData = (
  items: any[],
  searchTerm: string,
  options?: { searchInsideString?: boolean }
) => {
  return items.filter(
    (item) =>
      hasSubstring(item.name, searchTerm, options) ||
      hasSubstring(item.slug, searchTerm, options)
  )
}

export const getSearchResult = (data: { [any: string]: any }, searchTerm: string) => {
  const resultData = {
    companies: [],
    categories: [],
    jobTypes: [],
    skills: [],
    countries: [],
    cities: [],
    tags: [],
    profiles: [],
  }

  resultData.categories = filterData(data.categories, searchTerm)
  resultData.jobTypes = filterData(data.jobTypes, searchTerm)
  resultData.skills = filterData(data.skills, searchTerm)
  resultData.countries = filterData(data.countries, searchTerm)
  resultData.cities = filterData(data.cities, searchTerm)
  resultData.tags = filterData(data.tags, searchTerm)
  resultData.companies = filterData(data.companies, searchTerm, {
    searchInsideString: true,
  })
  resultData.profiles = filterData(data.profiles, searchTerm, {
    searchInsideString: true,
  })

  return resultData
}
