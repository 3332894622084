import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { FilterContext, FilterQueryData, IFilterData } from 'types/filter'
import FilterOptions from 'components/filter/Filter/FilterOptions'
import LastFilterSettings from 'components/filter/LastFilterSettings'
import useTranslation from 'translations/hooks/useTranslation'
import TrackingUtils from 'utils/tracking'
import FilterOptionsMobile from 'components/filter/Filter/FilterOptionsMobile'
import FilterTabs from 'components/filter/Filter/FilterTabs'
import FilterUtils from 'utils/filter'
import ActiveFilterSummary from 'components/filter/Filter/ActiveFilterSummary'
import JobUtils from 'utils/job'

type IProps = {
  filterData: IFilterData
  lastFilterSettings?: FilterQueryData | null
  extraElement?: ReactNode
  additionalFilter?: {
    new?: {
      count: number
      query: {
        key: string
        value: string
      }
    }
    salary?: {
      query: {
        key: string
      }
    }
  }
  query: any
  activeQueries?: any
  isActive?: boolean
  onReset?: (e: any) => void
  onResetSummary?: (e: any) => void
  onSelectOption: (key: string, value: string) => void
  onUnselectOption: (key: string, value: string) => void
  onReplaceOption?: (key: string, value: string | string[]) => void
  onLastFilterClick?: () => void
  resultsCount?: number
  context?: FilterContext
  onActiveFilterPillClick?: (key: string, value: string) => void
  salarySelectOptions?: {
    step?: number
    unit?: 'month' | 'hour'
  }
}
const Filter: FC<IProps> = ({
  lastFilterSettings,
  filterData,
  extraElement,
  additionalFilter,
  onReset,
  onReplaceOption,
  query,
  onUnselectOption,
  onSelectOption,
  onLastFilterClick,
  activeQueries,
  isActive,
  resultsCount,
  context,
  onResetSummary,
  onActiveFilterPillClick,
  salarySelectOptions,
}) => {
  const [activeTab, setActiveTab] = useState<keyof IFilterData | null>(null)
  const { t } = useTranslation()
  const tabsRef = useRef(null)
  const settingsRef = useRef(null)
  const optionsRef = useRef(null)
  const optionsMobileRef = useRef(null)

  useEffect(() => {
    const handleDocumentClick = (e: any) => {
      e.stopPropagation()

      if (
        tabsRef.current?.contains(e.target) ||
        optionsRef.current?.contains(e.target) ||
        optionsMobileRef.current?.contains(e.target) ||
        settingsRef.current?.contains(e.target)
      ) {
        return
      }

      setActiveTab(null)
    }

    document.addEventListener('click', handleDocumentClick)

    return () => document.removeEventListener('click', handleDocumentClick)
  }, [])

  const handleFilterPillClick = (key: keyof IFilterData) => {
    setActiveTab((prev) => {
      const shouldClose = prev === key

      TrackingUtils.event('filter', {
        event_action: shouldClose ? 'close' : 'open',
        event_data1: key,
      })

      return shouldClose ? null : key
    })
  }

  const trackFilterSelectOption = (
    key: string,
    value: string,
    additionalValue?: string
  ) => {
    const data = {
      event_data1: key,
      event_data2: value,
    }

    if (additionalValue) {
      data['event_data3'] = additionalValue
    }

    TrackingUtils.event(`filter`, {
      event_action: 'select-option',
      ...data,
    })
  }

  const trackFilerRemoveOption = (key: string, value: string) => {
    TrackingUtils.event(`filter`, {
      event_action: 'remove-option',
      event_data1: key,
      event_data2: value,
    })
  }

  const handleSelectFilterOption = (value: string) => {
    if (query[activeTab]?.includes(value)) {
      onUnselectOption(activeTab, value)
      trackFilerRemoveOption(activeTab, value)
    } else {
      onSelectOption(activeTab, value)
      trackFilterSelectOption(activeTab, value)
    }
  }

  const handleSalaryFilterValueChange = (value: string, currency: string) => {
    onReplaceOption?.(
      additionalFilter.salary.query.key,
      Number.parseInt(value) === 0 ? '' : [value, currency]
    )

    if (Number.parseInt(value) !== 0) {
      trackFilterSelectOption(additionalFilter.salary.query.key, value, currency)
      TrackingUtils.event(`filter`, {
        event_action: 'select-option',
        event_data1: 'salary',
        event_data2: value,
        event_data3: currency,
      })
    }
  }

  const handleToggleSingleOption = (key: string, value: string) => {
    if (!!query[key]?.length) {
      onUnselectOption(key, value)
      trackFilerRemoveOption(key, value)
    } else {
      onSelectOption(key, value)
      trackFilterSelectOption(key, value)
    }
  }

  const getSalaryFilterValue = () => {
    const salaryQueryValue = query[additionalFilter?.salary?.query?.key]?.[0]
    return salaryQueryValue ? salaryQueryValue.toString() : '0'
  }

  const getCurrencyFilterValue = () => {
    const currencyQueryValue = query[additionalFilter?.salary?.query?.key]?.[1]
    return currencyQueryValue ? currencyQueryValue : 'EUR'
  }

  function mapSkillLevels() {
    if (!filterData.skillLevels) {
      return []
    }

    return filterData.skillLevels
      .filter((sl) => sl.slug !== 'standard')
      .sort((a, b) => a.position - b.position)
      .map((sl) => ({
        ...sl,
        name: JobUtils.skillLevelSlugToText(sl.slug, t),
      }))
  }

  const getFilterOptions = () => {
    if (activeTab === 'skillLevels') {
      return mapSkillLevels()
    }

    if (activeTab === 'tags' && !!query.jobCategories.length) {
      return FilterUtils.filterTagsByCategories(filterData.tags, [
        ...query.jobCategories,
        'other',
      ])
    }

    if (activeTab === 'cities' && !!query.countries.length) {
      const activeCountries = filterData.countries?.filter(
        (country) => query.countries.indexOf(country.slug) !== -1
      )

      return filterData.cities?.filter((city) => {
        if (city.isRemote) {
          return query.countries.includes('remote')
        }
        return activeCountries.find((country) => country._id === city.country)
      })
    }

    return filterData[activeTab]
  }

  const filterOptionProps = {
    optionsKey: activeTab,
    options: getFilterOptions(),
    activeOptions: query,
    eventHandlers: {
      onSelectPill: handleSelectFilterOption,
      onSalaryChange: handleSalaryFilterValueChange,
      onTogglePill: handleToggleSingleOption,
      onSaveAndCloseClick: () => setActiveTab(null),
    },
    salaryValue: getSalaryFilterValue(),
    currencyValue: getCurrencyFilterValue(),
    salarySelectOptions,
  }

  const numberOfActiveFilters = Object.keys(activeQueries).length
  const hasMaxAgeQueryActive = activeQueries?.maxAge
  const renderNewJobsCount =
    numberOfActiveFilters === 0 || (numberOfActiveFilters === 1 && hasMaxAgeQueryActive)

  return (
    <>
      <div className="relative">
        <div className="filter__tabs container">
          <div className="filter__tabs-container" ref={tabsRef}>
            <FilterTabs
              onResetFilterClick={onReset}
              activeTab={activeTab}
              additionalFilter={{
                salary: !!additionalFilter?.salary,
                new: additionalFilter?.new
                  ? {
                      // NOTE: we want hide count when there are active filters
                      count: renderNewJobsCount ? additionalFilter.new.count : 0,
                      isActive: query[additionalFilter.new.query.key]?.length > 0,
                      onClick: () =>
                        handleToggleSingleOption(
                          additionalFilter.new.query.key,
                          additionalFilter.new.query.value
                        ),
                    }
                  : undefined,
              }}
              filterQuery={query}
              filterData={filterData}
              onTabClick={handleFilterPillClick}
            />
          </div>
          <div className="filter__extra-wrapper" ref={settingsRef}>
            <LastFilterSettings
              cities={filterData.cities}
              countries={filterData.countries}
              jobCategories={filterData.jobCategories}
              jobTypes={filterData.jobTypes}
              settings={lastFilterSettings}
              skillLevels={mapSkillLevels()}
              tags={filterData.tags}
              onLastFilterClick={onLastFilterClick}
              newJobsLabel
            />
            {extraElement}
          </div>
          {activeTab && (
            <>
              <div className="filter__options-transparent-overlay" />
              <div className="filter__options-absolute-wrapper" ref={optionsRef}>
                <FilterOptions {...filterOptionProps} />
              </div>
              <div className="filter__options-mobile-wrapper" ref={optionsMobileRef}>
                <FilterOptionsMobile
                  title={t(`filter.key[${activeTab}]`)}
                  {...filterOptionProps}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {isActive && activeQueries && (
        <div className="search container">
          <div className="search__container">
            <ActiveFilterSummary
              context={context}
              filterData={filterData}
              query={activeQueries}
              resultsCount={resultsCount || 0}
              onReset={onResetSummary}
              onPillClick={onActiveFilterPillClick}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default Filter
