import { FC } from 'react'
import Emoji from 'components/Emoji'

type IProps = {
  filterKey: string
  className?: string
}

const EMOJIS = {
  jobCategories: '📌',
  jobTypes: '💻',
  skillLevels: '🗡️',
  cities: '🏠',
  countries: '🌍',
  tags: '🧶',
  paymentTypes: '📄',
  salary: '💵',
  others: '🛠️',
  availabilities: '🟢',
}

const FilterKeyEmoji: FC<IProps> = ({ className, filterKey }) => {
  if (!EMOJIS[filterKey]) return null

  return <Emoji className={className}>{EMOJIS[filterKey]}</Emoji>
}

export default FilterKeyEmoji
