import { useState } from 'react'
import { SearchConfig } from 'types/search'
import { get } from 'utils/axios'

export const useSearch = (query: string, config?: SearchConfig) => {
  const [data, setData] = useState({
    companies: [],
    profiles: [],
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const getSearchUrl = () => {
    switch (config?.context) {
      case 'jobs':
      case 'companies':
        return 'api/company'
      case 'freelancers':
        return 'api/profile'
    }
  }

  const getStateKey = () => {
    switch (config?.context) {
      case 'jobs':
      case 'companies':
        return 'companies'
      case 'freelancers':
        return 'profiles'
    }
  }

  const search = async (value: string) => {
    if (config.context) {
      setIsLoading(true)
      setIsError(false)

      try {
        const params = { name: value }
        const { data } = await get(getSearchUrl(), {
          params,
        })
        setData((prev) => ({
          ...prev,
          [getStateKey()]: data.data,
        }))
      } catch (e) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    }
  }

  return {
    data,
    isError,
    isLoading,
    search,
  }
}
