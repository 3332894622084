import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import Link from 'next/link'
import type { FilterQuery } from 'types/filter'

interface IProps {
  item: { name: string; slug: string; _id: string | null }
  queryName?: keyof FilterQuery
  selectedId?: string | null
  onItemClick?: (key: keyof FilterQuery, value: string) => void
  urlConfig?: {
    prefix: string
    identifier: string
  }
}

const SearchResultItem: React.FC<IProps> = ({
  item,
  queryName,
  selectedId,
  onItemClick,
  urlConfig,
}) => {
  const elementRef = useRef(null)
  const isSelected = item._id === selectedId

  useEffect(() => {
    if (isSelected) {
      elementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
    }
  }, [isSelected])

  if (onItemClick) {
    return (
      <a
        ref={elementRef}
        onClick={() => {
          onItemClick(queryName, item.slug)
        }}
        className={classNames('search__result-item', {
          'search__result-item--active': isSelected,
        })}
      >
        {item.name}
      </a>
    )
  } else if (urlConfig) {
    return (
      <Link
        href={`${urlConfig.prefix}/${item[urlConfig.identifier]}`}
        ref={elementRef}
        className={classNames('search__result-item', {
          'search__result-item--active': isSelected,
        })}
      >
        {item.name}
      </Link>
    )
  }

  return null
}

export default SearchResultItem
