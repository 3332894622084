import React, { useEffect, useState } from 'react'
import { get } from 'utils/axios'
import { FilterQuery } from 'types/filter'
import FilterUtils from 'utils/filter'
import useTranslation from 'translations/hooks/useTranslation'

interface Props {
  settings: Partial<FilterQuery> | null
}

export default function NewJobsLabel({ settings }: Props) {
  const [newJobs, setNewJobs] = useState<number>(0)
  const { t } = useTranslation()

  useEffect(() => {
    const fetchNewJobsCount = async () => {
      try {
        const query = FilterUtils.buildJobsQuery(settings)
        const { data } = await get(`api/job/list/${query}`)
        setNewJobs(data?.newTotal || 0)
      } catch (error) {
        console.error('Failed to fetch new jobs count in NewJobsLabel:', error)
      }
    }
    fetchNewJobsCount()
  }, [settings])

  if (newJobs === 0) {
    return null
  }

  return (
    <div className="pill pill--orange pill--small">
      {t('label.newOfferCount', { count: newJobs })}
    </div>
  )
}
