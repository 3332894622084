import React from 'react'
import classNames from 'classnames'

type IProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

const RangeSlider = React.forwardRef<HTMLInputElement, IProps>(
  // eslint-disable-next-line react/prop-types
  ({ value, max = 0, style, className, ...props }, ref) => {
    // @ts-ignore
    const progress = (Number.parseInt(value) / max) * 100

    return (
      <input
        ref={ref}
        className={classNames('range-slider', className)}
        style={{
          background: `linear-gradient(to right, #000 ${progress}%, #777 ${progress}%)`,
          ...style,
        }}
        max={max}
        value={value}
        {...props}
        type="range"
      />
    )
  }
)

export default RangeSlider
