import React, { ChangeEvent, FC, ReactNode } from 'react'
import classnames from 'classnames'
import useTranslation from 'translations/hooks/useTranslation'

interface IProps {
  label: ReactNode
  additionalLabel?: ReactNode
  name: string
  value: any
  options: Array<{ value: string; text: string; disabled?: boolean }>
  required?: boolean
  withoutLabel?: boolean
  error?: boolean
  errorText?: string
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
  className?: string
  selectClassName?: string
}

const Select: FC<IProps> = ({
  label,
  required,
  name,
  value,
  error,
  errorText,
  withoutLabel,
  onChange,
  className,
  selectClassName,
  additionalLabel,
  ...props
}) => {
  const { t } = useTranslation()

  const getOptions = () => {
    const options = []

    props.options.forEach((data) => {
      options.push(
        <option value={data.value} key={data.value} disabled={data.disabled || false}>
          {data.text}
        </option>
      )
    })

    return options
  }

  return (
    <div
      className={classnames(
        'input-wrapper margin-top-small',
        {
          'input-wrapper--error': error,
        },
        className
      )}
    >
      {!withoutLabel && (
        <label className="label">
          {label}&nbsp;
          {required && <span className="paragraph--orange">*</span>}&nbsp;
          {additionalLabel}
        </label>
      )}

      <div className="select-wrapper">
        <select
          className={classnames('select', selectClassName)}
          name={name}
          value={value}
          onChange={onChange}
        >
          {getOptions()}
        </select>
        <div className="select-arrow-wrapper">
          <div className="select-arrow" />
        </div>
      </div>

      {error && (
        <p className="input__error-text input__error-text--visible">
          {errorText || t('common.requiredField')}
        </p>
      )}
    </div>
  )
}

export default Select
