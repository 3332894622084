import type { FilterBase, Tag } from 'types/filter'

export default class FilterUtils {
  static splitQueryString(query: string) {
    return query?.split(',')
  }

  static addKeyToQuery(query, key, value) {
    if (query.length === 0) {
      query += `?${key}=${value}`

      return query
    }

    query += `&${key}=${value}`

    return query
  }

  static buildJobsQuery(data) {
    let query = ''

    if (data.jobCategories && data.jobCategories.length > 0) {
      query = FilterUtils.addKeyToQuery(query, 'jobCategories', data.jobCategories)
    }

    if (data.skillLevels && data.skillLevels.length > 0) {
      query = FilterUtils.addKeyToQuery(query, 'skillLevels', data.skillLevels)
    }

    if (data.cities && data.cities.length > 0) {
      query = FilterUtils.addKeyToQuery(query, 'cities', data.cities)
    }

    if (data.countries && data.countries.length > 0) {
      query = FilterUtils.addKeyToQuery(query, 'countries', data.countries)
    }

    if (data.jobTypes && data.jobTypes.length > 0) {
      query = FilterUtils.addKeyToQuery(query, 'jobTypes', data.jobTypes)
    }

    if (data.tags && data.tags.length > 0) {
      query = FilterUtils.addKeyToQuery(query, 'tags', data.tags)
    }

    if (data.maxAge && data.maxAge.length > 0) {
      query = FilterUtils.addKeyToQuery(query, 'maxAge', data.maxAge)
    }

    if (data.available?.length) {
      query = FilterUtils.addKeyToQuery(query, 'available', data.available)
    }

    if (data.top?.length) {
      query = FilterUtils.addKeyToQuery(query, 'top', data.top)
    }

    if (data.pro?.length) {
      query = FilterUtils.addKeyToQuery(query, 'pro', data.pro)
    }

    if (data.paymentTypes?.length) {
      query = FilterUtils.addKeyToQuery(query, 'paymentTypes', data.paymentTypes)
    }

    if (data.searchTerm?.length) {
      query = FilterUtils.addKeyToQuery(
        query,
        'searchTerm',
        encodeURIComponent(data.searchTerm)
      )
    }

    if (data.showAll?.length) {
      query = FilterUtils.addKeyToQuery(query, 'showAll', data.showAll)
    }

    if (data.all === true) {
      query = FilterUtils.addKeyToQuery(query, 'all', data.all)
    }

    if (data.skip) {
      query = FilterUtils.addKeyToQuery(query, 'skip', data.skip)
    }

    if (data.page) {
      query = FilterUtils.addKeyToQuery(query, 'page', data.page)
    }

    if (data.salary?.length) {
      query = FilterUtils.addKeyToQuery(query, 'salary', data.salary)
    }

    if (data.availabilities?.length) {
      query = FilterUtils.addKeyToQuery(query, 'availabilities', data.availabilities)
    }

    return query
  }

  static buildSlugStringFromItems(items) {
    let str = ''

    items.map((item, index) => {
      str += index === 0 ? item.slug : `,${item.slug}`
    })

    return str
  }

  static filterTagsByCategories(tags: Tag[], categories?: string[]) {
    if (!categories) {
      return []
    }

    return tags.filter((tag) => categories.includes(tag?.category?.slug))
  }

  static groupCategoryData(data: any[]) {
    const categoriesOrder = [
      'marketeri',
      'dizajneri',
      'kreativci',
      'developeri',
      'nontech',
      'other',
    ]

    const groupedData = Object.values(
      data.reduce((accumulator, item) => {
        const { category, ...rest } = item

        if (!accumulator[category.slug]) {
          accumulator[category.slug] = { category, tags: [] }
        }

        accumulator[category.slug].tags.push(rest)

        return accumulator
      }, {})
    ).sort((a: { category: { slug: string } }, b: { category: { slug: string } }) => {
      return (
        categoriesOrder.indexOf(a.category.slug) -
        categoriesOrder.indexOf(b.category.slug)
      )
    }) as {
      category: FilterBase
      tags: Tag[]
    }[]

    return [...groupedData]
  }

  static parseRouterQueryToArrayValues<T extends object>(query, keys: string[]) {
    const parsedQuery = {}

    keys.forEach((key) => {
      parsedQuery[key] = FilterUtils.splitQueryString(query[key]) || []
    })

    return parsedQuery as T
  }
}
