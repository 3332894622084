import React, { FC, ReactNode } from 'react'
import classnames from 'classnames'
import emojiStrip from 'emoji-strip'
import emojiRegex from 'emoji-regex'
import Emoji from 'components/Emoji'
import useTranslation from 'translations/hooks/useTranslation'

type Props = {
  onClick(): void
  children?: ReactNode
  isActive?: boolean
  className?: string
  filterName?: string
  filterShortName?: string
  slug?: string
  type?: string
}

const FilterPill: FC<Props> = ({
  onClick,
  children = null,
  isActive,
  className,
  filterName,
  filterShortName,
  slug,
  type,
  ...props
}) => {
  const { t } = useTranslation()
  const regex = emojiRegex()
  const emoji = filterName?.match(regex)
  const name = slug === 'manifest' ? t('manifest.tag') : filterName
  const shortName = slug === 'manifest' ? t('manifest.tag') : filterShortName

  const filterNameWithoutEmoji = name && emojiStrip(name)
  const filterShortNameWithouEmoji = shortName && emojiStrip(shortName)

  return (
    <span
      className={classnames(
        'pill pill--filter',
        {
          'pill--filter-active': isActive,
          'pill--manifest': slug === 'manifest',
          'pill--content-after-emoji': type === 'countries',
        },
        className
      )}
      onClick={onClick}
      data-filter-name={filterNameWithoutEmoji}
      data-filter-short-name={filterShortNameWithouEmoji ?? filterNameWithoutEmoji}
      {...props}
    >
      <Emoji>
        {emoji}
        {children}
      </Emoji>
    </span>
  )
}

export default FilterPill
